"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomService = void 0;
class DomService {
    /**
     * TODO: test it
     * It's working
     * Source: https://www.abeautifulsite.net/posts/querying-through-shadow-roots/
     */
    shadowQuery(selector, rootNode = document) {
        const selectors = String(selector).split('>>>');
        let currentNode = rootNode;
        selectors.find((selector, index) => {
            var _a;
            if (index === 0) {
                currentNode = rootNode.querySelector(selector);
            }
            else if (currentNode instanceof Element) {
                currentNode = (_a = currentNode === null || currentNode === void 0 ? void 0 : currentNode.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector(selector);
            }
            return currentNode === null;
        });
        if (currentNode === rootNode) {
            return null;
        }
        return currentNode;
    }
    /**
     * @deprecated it's not in use, it can fail because of cross-origin security
     * Finds all elements, also from shadow roots
     */
    findAllChildElementsIncludingShadowRoots(root = document.body) {
        const childElements = [...Array.from(root.children)];
        function searchElementAndChildren(element) {
            if (element instanceof HTMLElement && element.shadowRoot) {
                console.log(`found element shadowRoot. Child elements = ${element.shadowRoot.children.length}`);
                childElements.push(...Array.from(element.shadowRoot.children));
                searchElementAndChildren(element.shadowRoot);
            }
            if (element instanceof HTMLElement && element.tagName === 'IFRAME') {
                try {
                    const iframe = element;
                    if (iframe.contentDocument) {
                        console.log(`found iframe elementss = ${iframe.contentDocument.body.children.length}`);
                        childElements.push(...Array.from(iframe.contentDocument.body.children));
                        searchElementAndChildren(iframe.contentDocument.body);
                    }
                }
                catch (error) {
                    console.error(error);
                }
            }
            for (const child of Array.from(element.children || [])) {
                searchElementAndChildren(child);
            }
        }
        searchElementAndChildren(root);
        return childElements;
    }
    findInnermostElementContaining(text) {
        // Get all elements that contain text.
        let elements = [...document.querySelectorAll(':not(:empty)')].filter(el => { var _a; return (_a = el.textContent) === null || _a === void 0 ? void 0 : _a.includes(text); });
        // Find the innermost element.
        const innermostElement = elements.reduce((innermost, current) => {
            let depthCurrent = 0;
            let depthInnermost = 0;
            let temp = current;
            while (temp) {
                depthCurrent++;
                temp = temp.parentElement;
            }
            if (innermost) {
                temp = innermost;
                while (temp) {
                    depthInnermost++;
                    temp = temp.parentElement;
                }
            }
            return !innermost || depthCurrent > depthInnermost ? current : innermost;
        }, null);
        // Now, `innermostElement` contains the innermost element with the text
        if (!innermostElement) {
            console.log(`No element found with "${text}".`);
            return null;
        }
        return innermostElement;
    }
    findParentWithRoleLink(element) {
        if (!element) {
            console.log('input element provided is null');
            return null;
        }
        while (element) {
            if (element.getAttribute('role') === 'link') {
                return element;
            }
            element = element.parentElement;
        }
        return null;
    }
}
exports.DomService = DomService;
