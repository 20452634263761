"use strict";
// noinspection SpellCheckingInspection
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnuncisWrapper = getAnuncisWrapper;
exports.mainRenew = mainRenew;
exports.mainRenewWrapper = mainRenewWrapper;
const luxon_1 = require("luxon");
const config_1 = require("./config");
const dom_service_1 = require("./dom-service");
const exceptions_1 = require("./exceptions");
const last_renew_attempt_manager_1 = require("./last-renew-attempt-manager");
const utils_1 = require("./utils");
const wallapop_utils_1 = require("./wallapop-utils");
// ------------ BEGIN CONFIG ----------------
const LOG_LEVEL = config_1.LogLevel.debug;
const TARGET_OAUTH_HOST = 'es.wallapop.com';
const HOST_ACCOUNTS_GOOGLE = 'accounts.google.com';
// ------------ END CONFIG ----------------
// Config from url
const url = document.location.href;
const host = document.location.host;
// const isTargetHost = host.indexOf(TARGET_OAUTH_HOST) !== -1;
const isGoogleOAuth = host.indexOf(HOST_ACCOUNTS_GOOGLE) !== -1 && url.indexOf(TARGET_OAUTH_HOST) !== -1;
let clickCookiesButtonInterval = null;
if (config_1.LogLevel.debug == LOG_LEVEL) {
    console.log(`isGoogleOAuth=${isGoogleOAuth}`);
    if (isGoogleOAuth) {
        console.log(`url=${url}`);
    }
}
const lastRenewedAttemptManager = new last_renew_attempt_manager_1.LastRenewAttemptManager(config_1.lratPk);
const domService = new dom_service_1.DomService();
const CSS_CONSTANTS = {
    BTN_RESERVE: ".btn-reserve",
    BTN_RESERVE_ACTIVE: ".active",
};
function getAnunciTitle(anunciCard) {
    try {
        return anunciCard.querySelector(".info-title").innerText;
    }
    catch (err) {
        try {
            return anunciCard.innerText;
        }
        catch (err) {
            return "<title not found>";
        }
    }
}
function findWallaButtonByShadowQuery() {
    return domService.shadowQuery('walla-button >>> button');
}
function findWallaButtonByText(text) {
    var _a;
    // search all walla-button
    const wallaButtons = document.querySelectorAll("walla-button");
    // loop through all walla-buttons
    for (let wallaButton of wallaButtons) {
        const has = (wallaButton.shadowRoot ? 'HAS' : 'DOESNT HAVE');
        console.log(`wallaButton found and ${has} shallow root`);
        if (wallaButton.shadowRoot) {
            const btn = wallaButton.shadowRoot.querySelector("button");
            if (btn && ((_a = btn.textContent) === null || _a === void 0 ? void 0 : _a.includes(text))) {
                console.log(btn);
                return btn;
            }
            console.log(`walla-button found but does not include text: "${text}"`);
            if (!btn) {
                console.log('button element under wallaButton was not found. Printing shadowRoot');
                console.log(wallaButton.shadowRoot.innerHTML);
            }
        }
        else {
            console.log('walla-button\'s shadowRoot not found. Printing document innerHTML');
            console.log(document.body.innerHTML);
        }
    }
    console.log('walla-button not found in the document. Printing document innerHTML');
    console.log(document.body.innerHTML);
    return null;
}
function getBtnThatContainsText(text) {
    var _a, _b;
    const elements = document.querySelectorAll(`body :not(script):not(style):not(template):not(noscript)`);
    const btn = findWallaButtonByText(text);
    if (btn) {
        console.log('found walla-button by text');
        return btn;
    }
    console.error('walla-button by text was not found.');
    const btn2 = findWallaButtonByShadowQuery();
    if (btn2) {
        console.log('findWallaButtonByShadowQuery was found');
        return btn2;
    }
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        // Look for the element with the specified text directly under the document body
        if ((_a = element === null || element === void 0 ? void 0 : element.textContent) === null || _a === void 0 ? void 0 : _a.includes(text)) {
            return element;
        }
    }
    console.error(`did not find button with text ${text} under the document body`);
    // If not found, look for the element with the specified text under any shallow root element
    const shallowRoots = document.querySelectorAll("body > *");
    for (let i = 0; i < shallowRoots.length; i++) {
        const childElement = shallowRoots[i].querySelector(":not(script):not(style):not(template):not(noscript)");
        if (!childElement) {
            continue;
        }
        if (childElement && ((_b = childElement === null || childElement === void 0 ? void 0 : childElement.textContent) === null || _b === void 0 ? void 0 : _b.includes(text))) {
            return childElement;
        }
    }
    console.error(`did not find button with text ${text} under the document body's shallow root elements`);
    // If still not found, return null
    return null;
}
/**
 * Tries to get the button with "Actualizar" text or else null
 */
function getBtnActualizar() {
    // scroll to bottom
    (0, utils_1.scrollToBottom)();
    const btn = document.querySelector('*[type="submit"]');
    if (btn) {
        console.log("found submit button");
        return btn;
    }
    // document.querySelector("#prueba > div.col-12.col-md-5 > walla-button").shadowRoot.querySelector("button > div > span")
    const text = "Actualizar";
    const actualizarElement = getBtnThatContainsText(text);
    // If still not found, return null
    if (!actualizarElement) {
        console.error("Actualizar element not found.");
    }
    return actualizarElement;
}
function getLastModificadoFromAnunciCard(anunciCard) {
    // last modificado
    let lastModificadoText = [...anunciCard.querySelectorAll(".item-info")].map(
    // @ts-ignore
    (e) => e.innerText);
    const regexModi = /Modificado/gi;
    lastModificadoText = lastModificadoText.filter((e) => regexModi.test(e)); //fecha
    if (lastModificadoText.length != 1) {
        console.error(`HTMLMarkupChangedError: FIX this: More than one lastModificado found for anunci: ${getAnunciTitle(anunciCard)}`);
        return null;
    }
    const regex = /\d{2}\/\d{2}\/\d{4}/gi;
    return String(lastModificadoText[0].match(regex)[0]);
}
/**
 * Returns an array of Anunci, filtering out the ones that are in a "reserved" state
 * @throws {Error} when no anuncis were found
 */
function getAnuncis() {
    console.log('getAnuncis: running...');
    const anunciCards = [...document.querySelectorAll(".CatalogItem__content")];
    let anuncis = [];
    for (const anunciCard of anunciCards) {
        const btnEdit = anunciCard.querySelector(".btn-edit");
        if (!btnEdit) {
            console.error(`HTMLMarkupChangedError: Not found button for anunci: ${getAnunciTitle(anunciCard)}`);
            continue;
        }
        const lastModificado = getLastModificadoFromAnunciCard(anunciCard);
        if (!lastModificado) {
            continue;
        }
        // trying to find the reserved css inside the card
        const btnReserved = anunciCard.querySelector(CSS_CONSTANTS.BTN_RESERVE);
        console.debug("btnReserved");
        console.debug(btnReserved);
        if (!btnReserved) {
            throw `HTMLMarkupChangedError: FIX this: css selector for reserved "${CSS_CONSTANTS.BTN_RESERVE}" is not found inside card: ${getAnunciTitle(anunciCard)}`;
        }
        const cssClassActive = CSS_CONSTANTS.BTN_RESERVE_ACTIVE.replace(".", "");
        // checking if the current anunci is reserved (by checking if it contains the class active)
        if ([...btnReserved.classList].indexOf(cssClassActive) != -1) {
            console.log(`Anunci ${getAnunciTitle(anunciCard)} is reserved. Skipping renew.`);
            continue;
        }
        anuncis.push({
            btnEdit,
            lastModificado: luxon_1.DateTime.fromFormat(lastModificado, config_1.DATETIME_FORMAT),
        });
    }
    if (!anuncis.length) {
        throw "HTMLMarkupChangedError: FIX this: html markup changed. No anuncis were found";
    }
    console.log('getAnuncis: returned...');
    return anuncis;
}
/**
 * @throws {Error} when no anuncis were found, or we are not in list page
 */
function getAnuncisWrapper() {
    if (!isListPage(document.location.href)) {
        throw Error('we are not in list page, cannot get the anuncis');
    }
    return getAnuncis();
}
function logError(err) {
    let errMsg = typeof err.toString == "function" ? err.toString() : "";
    if (err.stack) {
        errMsg += err.stack;
    }
    console.error(errMsg);
}
function getAnuncisToRenew() {
    let anuncis = getAnuncis();
    return anuncis.filter((a) => (0, utils_1.needToRenew)(a.lastModificado));
}
async function mainList() {
    console.log("mainList: running ...");
    const anuncisToRenew = getAnuncisToRenew();
    console.log(`anuncisToRenew length = ${anuncisToRenew.length}`);
    await lastRenewedAttemptManager.saveLastRenewedAttempt(anuncisToRenew.length);
    if (anuncisToRenew.length === 0) {
        console.log("ALL RENEWED: No hi ha més anuncis a renovar");
        window.finished = true;
        return;
    }
    for (const anunci of anuncisToRenew) {
        //only one will be clicked because it opens in the same tab
        anunci.btnEdit.click();
        await (0, utils_1.waitForTimeout)((0, utils_1.getRandBetween)(1500, 3000));
        await mainEditWrapper();
        break;
    }
}
/**
 * Wrapper to the function that picks the errors and logs them stringified so that they are picked by puppeteer.
 * Otherwise, it was not able to deserialize them. Was printing "ERR ERROR JSHandle@object {"timestamp":"2022-06-13 11:05:05"}"
 */
async function mainListWrapper() {
    try {
        await mainList();
    }
    catch (err) {
        logError(err);
    }
}
/**
 * Handles errors that can happen after submitting the "Actualizar" button and tries to recover some
 */
async function handleSubmitErrors() {
    try {
        await (0, wallapop_utils_1.intervalCheckForErrorMessage)(3);
    }
    catch (e) {
        if (e instanceof exceptions_1.InvalidInputException) {
            console.log('Invalid Input Exception');
            if ((0, utils_1.textIsPresent)('HP Deskjet 2050') ||
                (0, utils_1.textIsPresent)('no funciona al imprimir')) {
                console.log('trying to populate property');
                (0, wallapop_utils_1.populateModel)('Deskjet 2050');
                await (0, utils_1.waitForTimeout)((0, utils_1.getRandBetween)(500, 1000));
                console.log('trying to submit again');
                tryToSubmitBtnActualizar();
                return;
            }
        }
        else {
            console.error(`unknown error happened: ${e}`);
        }
        throw e;
    }
}
function tryToSubmitBtnActualizar() {
    const submit = getBtnActualizar();
    if (!(submit instanceof HTMLElement)) {
        console.error('error submit is not HTMLElement');
        return false;
    }
    console.log('----------------------------------');
    console.log('submitting');
    console.debug(`printing button actualizar`);
    console.debug(submit);
    console.log('----------------------------------');
    submit.click();
    return true;
}
async function mainEdit(tries = 0) {
    console.log("mainEdit: trying to submit form");
    const submit = getBtnActualizar();
    if (!submit) {
        if (tries < 2) {
            console.error(`Submit button not found. Retried ${tries} times ...`);
            await (0, utils_1.waitForTimeout)((0, utils_1.getRandBetween)(1500, 3000));
            return await mainEditWrapper(++tries);
        }
        console.error("FIX this: submit button not found. Aborting");
        return;
    }
    tryToSubmitBtnActualizar();
    await (0, utils_1.waitForTimeout)((0, utils_1.getRandBetween)(500, 1000));
    await handleSubmitErrors();
    console.log("calling mainList again");
    await mainListWrapper(); //call mainList again, that it will check that all anuncis are renewed
}
async function mainEditWrapper(tries = 0) {
    try {
        await mainEdit(tries);
    }
    catch (err) {
        logError(err);
    }
}
/**
 * Tries to click the login button
 */
function tryToClickCookiesButton() {
    const btnCookies = document.getElementById('onetrust-accept-btn-handler');
    if (btnCookies) {
        btnCookies.click();
        console.debug('ACCEPTAR CLICKED');
    }
}
function mainAll() {
    if (clickCookiesButtonInterval) {
        return;
    }
    // noinspection PointlessArithmeticExpressionJS
    clickCookiesButtonInterval = setInterval(tryToClickCookiesButton, 1 * 1000);
}
function isEditPage(url) {
    return url.includes("edit");
}
function isListPage(url) {
    return url.includes("published");
}
async function mainRenew() {
    // TODO: add await?
    mainAll();
    const thisUrl = document.location.href;
    let routerWorking = false;
    if (isGoogleOAuth) {
        routerWorking = true;
        console.debug("login wrapper is disabled");
        // await waitForTimeout(getRandBetween(700, 2000));
        // await mainLoginWrapper();
    }
    if (isEditPage(thisUrl)) {
        routerWorking = true;
        await (0, utils_1.waitForTimeout)((0, utils_1.getRandBetween)(3000, 5000));
        await mainEditWrapper();
    }
    if (isListPage(thisUrl)) {
        routerWorking = true;
        await (0, utils_1.waitForTimeout)((0, utils_1.getRandBetween)(5000, 10000));
        await mainListWrapper();
    }
    if (thisUrl.includes('app/chat')) {
        routerWorking = true;
    }
    // if (!routerWorking) {
    //     throw new Error(
    //         "The conditions to check if the url is the list of ads or a particular edit page are not set up appropriately. Check the code and fix it according to the URL."
    //     );
    // }
}
async function mainRenewWrapper(retries = 0) {
    try {
        await mainRenew();
    }
    catch (err) {
        console.error(err.stack);
        if (retries < 3) {
            await mainRenewWrapper(++retries);
        }
    }
}
